<template>
  <div>
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <div class="center-logo">
            <app-logo></app-logo>
          </div>
          <v-card class="rounded-xl pt-4 px-6" flat>
            <div class="checkout-container" id="checkout-container"></div>
            <div class="order-summary-container">
              <h4>Order Summary</h4>
              <div class="w-full mt-2">
                {{ order.plan_name }}
                <v-spacer></v-spacer>
                USD {{ order.subtotal }}
              </div>
              <div class="w-full mt-2">
                VAT/Sales Tax
                <v-spacer></v-spacer>
                USD {{ order.tax }}
              </div>
              <div class="w-full mt-2">
                Total
                <v-spacer></v-spacer>
                USD {{ order.total }}
              </div>
            </div>
            <v-card-text class="text-center">
              <p class="support-contact-box">
                <v-icon size="18px" color="black" class="mr-1">
                  mdi-email-fast
                </v-icon>
                Need help? &#8211;
                <a href="mailto:support@konvey.app">support@konvey.app</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppLogo from "../../components/Logo";
import ResponseHelper from "../../utils/ResponseHelper";
import usePaddle from "../../composables/usePaddle";

export default {
  name: "NewUserCheckout",
  components: {
    AppLogo,
  },
  data() {
    return {
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
      paddleInitialized: false,
      order: {
        plan_name: null,
        subtotal: null,
        tax: null,
        total: null,
      },
    };
  },
  async created() {
    const { initializeInlinePaddleInstance } = usePaddle();

    this.loading = true;

    // Ensure order summary updates localStorage before Paddle initializes
    await this.getOrderSummary();

    // Now that localStorage is updated, initialize Paddle
    await initializeInlinePaddleInstance();

    this.paddleInitialized = true;
    await this.initializeCheckout();

    this.loading = false;
  },
  methods: {
    async initializeCheckout() {
      const { openInlineCheckout } = usePaddle();
      if (this.paddleInitialized) {
        const priceId = this.$route.params.priceId;
        openInlineCheckout(priceId);
      } else {
        console.error("Paddle instance is not initialized.");
      }
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount / 100); // Divide by 100 to convert from cents to dollars
    },
    async getOrderSummary() {
      this.loading = true;
      try {
        const priceId = this.$route.params.priceId;
        const response = await window.axios.get(`/payments/order/${priceId}`);
        const data = response.data.data;

        localStorage.setItem("plan_name", data["name"]);

        // Calculate the correct subtotal and tax
        const taxRate = 7.5;
        const totalAmount = data.unit_price.amount;

        // Calculate subtotal and tax
        const subtotal = totalAmount / (1 + taxRate / 100);
        const tax = totalAmount - subtotal;

        // Format and display the amounts in the correct units (dollars)
        this.order.plan_name = data.name;
        this.order.subtotal = this.formatCurrency(subtotal);
        this.order.tax = this.formatCurrency(tax);
        this.order.total = this.formatCurrency(totalAmount);
        this.loading = false;
      } catch (e) {
        this.toast.sendError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.bold-text {
  font-weight: 900;
}
.support-contact-box {
  font-size: 12px;
  margin-top: 6px;
  align-content: center;
}
.checkout-container {
  width: 100%;
  min-width: 312px;
  background-color: transparent;
  border: none;
}
.order-summary-container {
  text-align: left;
  padding: 10px;
}
.w-full {
  width: 100%;
  display: flex;
  background-color: #eee;
  padding: 4px 6px;
}
</style>
